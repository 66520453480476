<template>
    <div class="role public_bg">
        <Top  name="角色提升" email back v-if="topShow"></Top>
        <van-loading v-if="isLoading" color="#1989fa" />
        <div class="public_tw" v-else>
            <div class="public_sheet" v-if="list.base_data">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">转生</div>
                        <ul>
                            <li><span>转生：</span><span>{{list.base_data.transform}}</span></li>
                        </ul>
                    </div>
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">官职</div>
                        <ul>
                            <li><span>官职：</span><span>{{list.base_data.position?list.base_data.position:'无'}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.base_data">
                <div class="public_main">
                    <!-- <div class="public_main_outside">
                        <div class="public_main_outside_top">潜能 </div>
                        <ul>
                            <li><span>致命：</span><span>{{list.base_data.kill}}</span></li>
                            <li><span>碎甲：</span><span>{{list.base_data.sunder}}</span></li>
                            <li><span>利刃：</span><span>{{list.base_data.sharp}}</span></li>
                            <li><span>坚甲：</span><span>{{list.base_data.firm}}</span></li>
                            <li v-if="params.channel_id==5"><span>吸血：</span><span>{{list.base_data.xixue}}</span></li>
                            <li v-if="params.channel_id==5"><span>强身：</span><span>{{list.base_data.qiangshen}}</span></li>
                            <li v-if="params.channel_id==5"><span>抵抗：</span><span>{{list.base_data.dikang}}</span></li>
                            <li v-if="params.channel_id==5"><span>强掠：</span><span>{{list.base_data.qianglve}}</span></li>
                        </ul>
                    </div> -->
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">小魔神</div>
                        <ul>
                            <li><span>魔神之体：</span><span>{{list.devil.level}}</span></li>
                            <li><span>嗜血：</span><span>{{list.devil.bloodthirsty}}</span></li>
                            <li><span>穿杨：</span><span>{{list.devil.piercing}}</span></li>
                            <li><span>强化：</span><span>{{list.devil.streng}}</span></li>
                            <li><span>精准：</span><span>{{list.devil.precise}}</span></li>
                        </ul>
                    </div>
                    <div class="public_main_outside" v-if="list.devil">
                        <div class="public_main_outside_top">大魔神</div>
                        <ul>
                            <li><span>大魔神：</span><span>{{list.devil.big_level?list.devil.big_level:'无'}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="public_sheet" v-if="list.devil">
                <div class="public_main">
                    
                    <div class="public_main_outside public_none">
                        <div class="public_main_outside_top public_none"></div>
                        <ul>
                            <li><span>进阶魔神：</span><span>{{list.devil.pro_level}}</span></li>
                            <li><span>穿透：</span><span>{{list.devil.pro_chuantou}}</span></li>
                            <li><span>穿杨：</span><span>{{list.devil.pro_piercing}}</span></li>
                            <li><span>强化：</span><span>{{list.devil.pro_streng}}</span></li>
                            <li><span>强生：</span><span>{{list.devil.pro_qiangsheng}}</span></li>
                        </ul>
                    </div>
                </div>
            </div> -->
            <div class="public_sheet" v-if="list.spirit">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">炼器</div>
                        <ul>
                            <li><span>武器：</span><span>{{list.spirit.weapons}}</span></li>
                            <li><span>衣服：</span><span>{{list.spirit.clothes}}</span></li>
                            <li><span>头盔：</span><span>{{list.spirit.helmet}}</span></li>
                            <li><span>项链：</span><span>{{list.spirit.necklace}}</span></li>
                        </ul>
                    </div>
                    <div class="public_main_outside public_none">
                        <div class="public_main_outside_top public_none"></div>
                        <ul>
                            <li><span>手镯：</span><span>{{list.spirit.bracelet}}</span></li>
                            <li><span>戒指：</span><span>{{list.spirit.ring}}</span></li>
                            <li><span>腰带：</span><span>{{list.spirit.belt}}</span></li>
                            <li><span>鞋子：</span><span>{{list.spirit.shoes}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.title">
                <div class="public_main">
                    <div class="public_main_outside">
                    <div class="public_main_outside_top">称号养成</div>
                    <ul>
                        <li>
                            <span>称号：</span><span>{{ list.title.title_name }}{{list.title.lv+'级'}}</span>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.base_data">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">潜能</div>
                        <ul>
                            <li><span>致命：</span><span>{{list.base_data.kill}}</span></li>
                            <li><span>碎甲：</span><span>{{list.base_data.sunder}}</span></li>
                            <li><span>利刃：</span><span>{{list.base_data.sharp}}</span></li>
                            <li><span>坚甲：</span><span>{{list.base_data.firm}}</span></li>
                        </ul>
                    </div>
                    <div class="public_main_outside public_none">
                        <div class="public_main_outside_top public_none"></div>
                        <ul>
                            <li><span>噬血：</span><span>{{list.base_data.xixue}}</span></li>
                            <li><span>强身：</span><span>{{list.base_data.qiangshen}}</span></li>
                            <li><span>闪避：</span><span>{{list.base_data.qianglve}}</span></li>
                            <li><span>抵抗：</span><span>{{list.base_data.dikang}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.magic">
                <div class="public_main">
                    <div class="public_main_outside" v-if="list.magic.treasure">
                        <div class="public_main_outside_top">法宝 </div>
                        <ul>
                            <li><span>法宝：</span><span>{{list.magic.treasure}}</span></li>
                        </ul>
                    </div>
                    <div class="public_main_outside public_none" v-if="list.magic.stage">
                        <div class="public_main_outside_top">境界</div>
                        <ul>
                            <li><span>境界：</span><span>{{list.magic.stage}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.soul">
                <!-- atk	int	魔攻道
                def	int	双防
                hp	int	hp
                mp	int	mp
                monster_up	int	对怪物伤害提高
                phy_reduce	int	物理伤害减少
                mgc_reduce	int	魔法伤害减少 -->
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">淬魂</div>
                        <ul>
                            <li><span>魂力：</span><span>{{list.soul.hunli}}</span></li>
                            <!-- <li><span>HP：</span><span>{{list.soul.hp}}</span></li>
                            <li><span>对怪物伤害提高：</span><span>{{list.soul.monster_up}}</span></li>
                            <li><span>物理伤害减少：</span><span>{{list.soul.phy_reduce}}</span></li>
                            <li><span>魔法伤害减少：</span><span>{{list.soul.mgc_reduce}}</span></li> -->
                        </ul>
                    </div>
                    <div class="public_main_outside public_none">
                        <div class="public_main_outside_top public_none"></div>
                        <ul>
                            <li><span>魂印：</span><span>{{list.soul.hunyin}}</span></li>
                            <!-- <li><span>MP：</span><span>{{list.soul.mp}}</span></li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="public_sheet" v-if="list.skill">
                <div class="public_main">
                    <div class="public_main_outside">
                        <div class="public_main_outside_top">技能</div>
                        <ul>  
                            <li v-for="(item, index) in list.skill" :key="index" ><span>{{item.name}}{{item.level}}级   经验：</span><span>{{item.exp}}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Top from '@/components/top'
export default {
    name:"role",
    data(){
        return {
            params: {},
            list:[],
            loading: true,
            topShow: true,
            isLoading:true
        }
    },
    components: {
      Top
    },
    created(){
        //获取路由参数
        this.params = this.$route.query;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        this.getEquipmentData();
    },
    methods:{
        //获取龙纹数据
        getEquipmentData() {
            this.$api.shopping.getAttach(this.params).then(res => {
                this.isLoading = false
                if (res.code == 200) {
                    // this.loading = false;
                    this.list = res.data;
                    // this.loading = false;
                    // console.log(res)
                    // this.list = res.data;
                }
            }).catch(err => {
                this.isLoading = false
                console.log(err);
            });
        }
    }
    
}
</script>
<style scoped>
@import "../../assets/css/common.css"
</style>